import * as React from "react"

import Layout from "../components/Layout/"
import Seo from "../components/seo"
import Home from "../components/Home/"

const SecondPage = () => (
  <Layout>
    <Seo />
    <Home />
  </Layout>
)

export default SecondPage
