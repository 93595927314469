import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FooterHome = () => (
  <StaticImage
    src="../../images/vertgreen-jardim-fachada.png"
    width={600}
    quality={100}
    alt="Vert Green - Jardins Verticais"
    style={{ marginBottom: "-10px", marginTop: "auto" }}
  />
)

export default FooterHome
