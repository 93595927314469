import * as React from "react"

import * as S from "./styles"
import FooterHome from "../FooterHome"

import logo from "../../images/vert-green-logo-vertical.svg"

const Home = () => (
  <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
    <S.Wrapper>
      <img src={logo} alt="Vertgreen" />
      <S.A href="https://vertgreen.com.br" target="_blank">
        Acesse nosso site
      </S.A>
      <S.Button to="/whatsapp">Nosso WhatsApp</S.Button>
      <S.Button to="/form">Se torne uma revenda</S.Button>
    </S.Wrapper>
    <FooterHome />
  </div>
)

export default Home
