import styled, { css } from "styled-components"
import { Link } from "gatsby"

const baseButton = css`
  background: #d8d6d6;
  margin: 8px 0;
  color: #4d4e4e;
  padding: 8px;
  width: 100%;
  text-align: center;
  display: block;
  text-decoration: none;
  border-radius: 16px;
`

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  img {
    width: 120px;
    margin: 40px 0;
  }
`

export const Button = styled(Link)`
  ${baseButton}
`

export const A = styled.a`
  ${baseButton}
`
